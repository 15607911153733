<template>
  <vx-card title="Import Promotion Claim">
    <vs-tabs>
      <promotion-claim></promotion-claim>
    </vs-tabs>
  </vx-card>
</template>
<script>
import PromotionClaim from "./promotion_claim";
export default {
  components: {
    "promotion-claim" : PromotionClaim
  }
};
</script>