<template>
    <div class="tab-text">
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Supplier</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                    v-model="supplier.selected"
                    :options="supplier.options"
                    placeholder=" Type to search"
                    track-by="ID"
                    label="Name"
                    :max-height="125"
                    >
                    <template slot="singleLabel" slot-scope="dt">
                        <span class="option__desc">
                        <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
                        </span>
                    </template>

                    <template slot="option" slot-scope="dt">
                        <div class="option__desc">
                        <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
                        </div>
                    </template>
                </multiselect>
                <span class="text-danger text-sm"
                v-show="errors.has('SupplierCustomer')"
                >{{ errors.first("SupplierCustomer") }}</span
                >
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <datepicker
                class="w-full"
                v-validate="'required'"
                :inline="false"
                name="PostingDate"
                placeholder="Select Date"
                v-model='postingDate'
                ></datepicker>
                <span class="text-danger text-sm" v-show="errors.has('PostingDate')">{{ errors.first('PostingDate') }}</span>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>File</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <input
                class="inputx"
                type="file"
                v-if="uploadReady"
                ref="file"
                name="file"
                accept=".xls, .xlsx"
                />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-button class="ml-auto mt-2" v-on:click="handleSubmit">Submit</vs-button>
                <!-- <vs-button
                    class="ml-4 mt-2"
                    type="border"
                    color="warning"
                    v-on:click="handleDownload"
                    >Download Template</vs-button> -->
            </div>
        </div>
        <vs-alert v-if="successMessage.length > 0" color="warning" >
            <template v-for="(dt) in successMessage">
                <div v-bind:key="dt" class="mb-2">
                    {{dt}}
                </div>
            </template>
        </vs-alert>
        <vs-alert v-if="errorMessage.length > 0" color="danger" >
            <template v-for="(dt) in errorMessage">
                <div v-bind:key="dt" class="mb-2">
                    {{dt}}
                </div>
            </template>
        </vs-alert>
        
        <div class="vx-row mb-12">
			<div
				v-bind:class="[
				detail ? detailShow + ' vertical-divider' : '',
				detailHide,
				]"
			>
        <vs-table
            search
            stripe
            border
            description
            :sst="true"
            :data="table.data"
            :max-items="table.length"
            :total="table.total"
            @search="handleSearch"
            @change-page="handleChangePage"
            @sort="handleSort"
        >
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                    class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2">{{this.table.start}} - {{this.table.end}} of {{this.table.total}}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item
                        v-for="item in table.limits"
                        :key="item"
                        @click="handleChangelength(item)"
                    >
                    <span>{{item}}</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>No</vs-th>
                <vs-th sort-key="file">File</vs-th>
                <vs-th>Description</vs-th>
                <vs-th sort-key="date">Date</vs-th>
                <vs-th>User</vs-th>
                <vs-th>Status</vs-th>
                <vs-th>Action</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>{{ indextr+1 }}</vs-td>
                    <vs-td :data="data[indextr].file"><a href="#" v-on:click="downloadFileAwsS3(tr.file)">File Uploaded</a></vs-td>
                    <vs-td :data="data[indextr].description">{{ data[indextr].description }}</vs-td>
				    <vs-td :data="data[indextr].time">{{ dateFormat(tr.time) }}</vs-td>
                    <vs-td>{{ tr.username }}</vs-td>
                    <vs-td>
                        Total Document : {{ tr.document_activity }} <br>
                        Document Success :  {{ tr.document_success }}<br>
                        Document Failed :  {{ tr.document_failed }}<br>
                        Detail Success :  {{ tr.document_activity_success }}<br>
                        Detail Failed : {{ tr.document_activity_failed }}
                    </vs-td>
                    <vs-td>
                        <a><vs-button class="mt-2 ml-2" color="primary" type="border" icon-pack="feather" icon="icon-search" v-on:click="showDetail(tr)"></vs-button></a>
                    </vs-td>
                </vs-tr>
            </template>
            <!-- <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ indextr + 1 }}</vs-td>
              <vs-td :data="data[indextr].description">{{
                data[indextr].description
              }}</vs-td>
              
              <vs-td :data="data[indextr].file"><a href="#" v-on:click="downloadFileAwsS3(tr.file)">Uploaded file</a></vs-td>
              <vs-td :data="data[indextr].date">{{ dateFormatGMT7(tr.time) }}</vs-td>
              <vs-td :data="data[indextr].date">{{ data[indextr].username }}</vs-td>
              <vs-td :data="data[indextr].total_data">
                Total Document : {{data[indextr].total_data}} <br>
                Total Success : {{ tr.document_activity_success }} <br>
				Total Failed : {{ tr.document_activity_failed }}
              </vs-td>
              <vs-td>
                <a
                  ><vs-button
                    class="mt-2 ml-2"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    icon="icon-search"
                    v-on:click="showDetail(tr)"
                  ></vs-button
                ></a>
              </vs-td>
            </vs-tr>
          </template> -->
        </vs-table>
        <vs-pagination
            style="padding-top:5px"
            :total="table.totalPage"
            v-model="setPage"
        />
        </div>
        <vs-popup fullscreen title="Log Details" :active.sync="detail">
			<div class="vx-row mb-6">
				<div class="vx-col w-full" v-if="detail">
					<datatableDetail :selected="selected"></datatableDetail>
				</div>
			</div>
		</vs-popup>
		<!-- detail-->
		<!-- <transition name="detail-fade">
			<div
			v-if="detail"
			v-bind:class="[
				detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
				detailHide,
			]"
			>
            <div>
				<vs-col
				style="padding-bottom: 8px"
				vs-offset="8"
				vs-type="flex"
				vs-justify="rigth"
				vs-align="rigth"
				vs-w="4"
				>
				<vs-button
					size="small"
					v-on:click="closeDetail"
					color="grey"
					icon-pack="feather"
					icon="icon-x-square"
					>Close</vs-button
				>
				</vs-col>
			</div>
			<vs-list>
				<vs-list-item  v-for="item in selected.detail_activity" :key="item.ReferenceCode" :subtitle="'Line :'+item.Number+'; ( Dist : '+JSON.parse(item.JsonData)[0] +' ) ' +item.ReferenceCode+' '+ JSON.parse(item.JsonData)[11] +' => '+item.Status +' ' + item.Note"></vs-list-item>
			</vs-list>
			</div>
		</transition> -->
		</div>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from 'moment'
import datatableDetail from "./datatable-detail.vue";
export default {
    components: {
        Datepicker,
        datatableDetail
    },
    data() {
        return {
            uploadReady: true,
            errorMessage: [],
            successMessage: [],
			detail: false,
			detailShow: "vx-col md:w-1/2 w-full mb-base",
			detailHide: "core vx-col md:w-1/1 w-full mb-base",
            table: {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 0,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0
            },
            supplier: {
                options: [],
                selected: null,
            },
            selected: null,
            postingDate: new Date().toISOString().slice(0,10),
        };
    },
    methods: {
        handleDownload() {
            // window.open(process.env.VUE_APP_CDN_ADDRESS+'/'+process.env.VUE_APP_CDN_BUCKET+'/templates/TemplateCustomer.xlsx');
        },
        handleSubmit() {
            this.$validator.validateAll().then((result) => {
                if (!this.supplier.selected) {
                this.errors.add({
                    field: "SupplierCustomer",
                    msg: "The supplier field is required",
                });
                result = false;
                }

                this.errorMessage = []
                this.successMessage = []
                var file = this.$refs.file.files[0];
                if (file == null) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Form Validation",
                        text: "File is required",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                } else {
                    var dateNow = new Date();
                    var now =
                        dateNow.getFullYear() +
                        "" +
                        (dateNow.getMonth() + 1) +
                        "" +
                        dateNow.getDate();
                    now +=
                        +"" +
                        dateNow.getHours() +
                        "" +
                        dateNow.getMinutes() +
                        "" +
                        dateNow.getSeconds();
                    this.formData = new FormData();
                    this.formData.append("file", file);
                    this.formData.append("user_agent", navigator.userAgent)
                    this.formData.append("file_name", (now + "_" + file.name));
                    this.formData.append("url", "/api/v1/import/promotion-claim");
                    this.formData.append("posting_date", moment(this.postingDate).format('YYYY-MM-DD'));
                    this.formData.append("supplier_id", this.supplier.selected.id ? this.supplier.selected.id : this.supplier.selected.ID);
                    this.$vs.loading();
                    this.$http
                    .post("/api/v1/import/new-promotion-claim", this.formData, {
                        headers: {
                        "Content-Type": "multipart/form-data",
                        }
                    })
                    .then((resp) => {
                        this.$vs.loading.close();
                        if (resp.status == "success") {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                            this.uploadReady = false;
                            this.$nextTick(() => {
                                this.uploadReady = true;
                            });
                            this.successMessage = resp.data
                            this.getData()
                        } else {
                            this.errorMessage = resp.data
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: error,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    });
                }
            });
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        closeDetail() {
		    this.detail = false;
		},
        showDetail(data) {
            // this.selected = this.table.data[index];
            // this.detail = true;
            this.selected = data.id;
			this.detail = true;
		},
        getData() {
            this.$vs.loading();
            this.$http
            .get("/api/v1/import/history-activity", {
                params: {
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    type: "promotion-claim"
                }
            })
            .then(resp => {
                if (resp.status == "success") {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    console.log(resp.message);
                }
            });
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;
            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;
            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }
            if (this.table.totalSearch < this.table.total) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        getOptionSupplier() {
            this.$vs.loading();
            this.$http
            .get("/api/v1/master/suppliers", {
            params: {
                order: "name",
                sort: "asc",
            },
            })
            .then((resp) => {
            if (resp.code == 200) {
                this.supplier.options = resp.data.records;
            } else {
                this.$vs.notify({
                title: "Error",
                text: "Failed to get Supplier option",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
                });
            }
            this.$vs.loading.close();
            });
        },
        dateFormatGMT7(date) {
            if (date) {
				// ini karena datanya berformat `date time`, bukan `datetimezone`
				// jadi GMT(+7) ditambah +7 =>utc offset = 14
                return moment(String(date)).utcOffset(14).format('DD/MM/YYYY HH:mm')
            }
        },
        dateFormat(date) {
            if (date) {
                return moment(String(date)).format("DD-MM-YYYY HH:mm");
            }
        },
        
    },
    mounted() {        
        this.getOptionSupplier();
    },
    watch: {},
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            }
        },
    }
};
</script>


 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>